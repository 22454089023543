import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import FullLogo from "assets/images/fullLogo.png";
import Logo from "assets/images/logo.png";
import Profile from "./Profile";
import useCookie from "hooks/useCookie";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { setLocalConfig } from "utils";

const Header = ({ fullMenu, setFullMenu }) => {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);
  const { removeCookie } = useCookie();

  //***********Handel Logout********** */
  const handelLogout = (e) => {
    e.preventDefault();
    removeCookie();
    localStorage.removeItem(process.env.REACT_APP_AUTH);
    navigate("/");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  const menuToggle = () => {
    setFullMenu(!fullMenu);
    setLocalConfig("menu", !fullMenu);
  };

  return (
    <React.Fragment>
      <div className="Header">
        <div className={fullMenu ? "Logo" : "Small-Logo"}>
          <Link to="/dashboard">
            <img src={fullMenu ? FullLogo : Logo} alt="logo" />
            {/* <h3>Tele Medicine</h3> */}
          </Link>
        </div>
        <div className="Navigation">
          <div>
            <IconButton aria-label="menu" onClick={menuToggle}>
              {fullMenu ? (
                <MenuOpenIcon color="primary" />
              ) : (
                <MenuIcon color="primary" />
              )}
            </IconButton>
          </div>
          <div className="Avater">
            <Profile />
          </div>
        </div>

        <div
          className="d-block d-lg-none d-xl-none px-4"
          onClick={() => setDrawer(true)}
        >
          <i className="fa fa-bars open"></i>
        </div>
      </div>

      <MobileMenu
        toggleDrawer={toggleDrawer}
        drawer={drawer}
        onClose={() => setDrawer(false)}
        handelLogout={handelLogout}
      />
    </React.Fragment>
  );
};

export default Header;
