import { Button } from "components/Button/Button";
import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalBox = ({
  status,
  onClose,
  title,
  description,
  action,
  customizedFooter,
  children,
  size,
  custom,
  closeIcon,
}) => {
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  // avoid close on back
  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={status}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={size || "xs"}
      >
        {closeIcon && (
          <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
            {title}
          </BootstrapDialogTitle>
        )}

        {!closeIcon && (
          <DialogTitle id="alert-dialog-title" align={"center"}>
            {title}
          </DialogTitle>
        )}
        <DialogContent className="modal-body">
          {description}
          {children && children}
        </DialogContent>
        <DialogActions className="modal-footer">
          {!customizedFooter ? (
            <>
              <Button onClick={onClose}>No</Button>
              <Button onClick={action}>Yes</Button>
            </>
          ) : (
            <>
              {!custom && (
                <Button onClick={onClose} className="mx-2">
                  No
                </Button>
              )}

              {customizedFooter}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalBox;

{
  /* <Modal show={status} onHide={onClose} animation={true}>
        <Modal.Header closeButton onClick={onClose}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {description}
          {children && children}
        </Modal.Body>
        <Modal.Footer>
          {!customizedFooter ? (
            <>
              <button variant="secondary" onClick={onClose}>
                No
              </button>
              <button variant="primary" onClick={action}>
                Yes
              </button>
            </>
          ) : (
            <>
              <Button onClick={onClose} className="mx-2">
                No
              </Button>
              {customizedFooter}
            </>
          )}
        </Modal.Footer>
      </Modal> */
}
