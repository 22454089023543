import ModalBox from "components/modal/ModalBox";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { sidebar } from "routes/sidebar";
import SubMenu from "./SubMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import useCookie from "hooks/useCookie";
import { useStaffAuth } from "context/context";
import useDropdownToggler from "hooks/useDropdownToggler";

const LeftSideBar = ({ fullMenu }) => {
  const history = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const { removeCookie } = useCookie();
  const { roles } = useStaffAuth();
  const [style, ddToggler] = useDropdownToggler();

  const handelLogout = (e) => {
    e.preventDefault();
    removeCookie();
    localStorage.removeItem(process.env.REACT_APP_AUTH);
    history("/");
  };

  return (
    <React.Fragment>
      <div className={fullMenu ? "SidenavBar" : "SidenavBar-Small"}>
        <ul>
          {sidebar(roles).map(({ path, icon, name, isChild }) => {
            return isChild ? (
              <SubMenu
                isChild={isChild}
                icon={icon}
                path={path}
                name={name}
                key={path}
                style={style}
                fullMenu={fullMenu}
                toggle={() => ddToggler(path)}
              />
            ) : (
              <li className="" key={path}>
                <NavLink to={path}>
                  <span>{icon}</span>
                  {fullMenu ? name : <>&nbsp;</>}
                </NavLink>
              </li>
            );
          })}
          <li>
            <a onClick={() => setLogoutModal(true)}>
              <span>
                {/* <i className="fa fa-sign-out"></i> */}
                <LogoutIcon fontSize="small" />
              </span>
              {fullMenu ? "Logout" : <>&nbsp;</>}
            </a>
          </li>
        </ul>
      </div>

      {/* logout modal */}

      <ModalBox
        status={logoutModal}
        onClose={() => setLogoutModal(false)}
        title={"Logout"}
        description={"Are you sure want to logout ?"}
        action={handelLogout}
      />
    </React.Fragment>
  );
};

export default LeftSideBar;
