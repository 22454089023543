import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import Logo from "assets/images/fullLogo.png";
import useCookie from "hooks/useCookie";
import { login } from "services/auth";
import { useStaffAuth } from "context/context";
import { encryptData, normalEncryptData } from "config/config";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setError] = useState({ email: "", password: "" });
  const { setCookie } = useCookie();
  const { setRoles } = useStaffAuth();
  const [submitButtonElement, setSubmitLoading] = useButtonLoader(
    "Log In",
    "Please wait..."
  );
  //************Handel Login Form Validation********************** */
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors["email"] = "Please enter Email Id";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "Please enter Password";
    }
    setError(errors);
    return formIsValid;
  };

  //*************Handel User Login ************************/
  const userLogin = async (e) => {
    e.preventDefault();
    setCookie("1234");
    if (handleValidation()) {
      setSubmitLoading(true);
      const res = await login({ email, password });
      setSubmitLoading(false);
      if (res.code === 200) {
        setCookie(res.data.access_token);
        setRoles([res.data?.roleId]);
        localStorage.setItem(
          process.env.REACT_APP_AUTH,
          encryptData([res.data?.roleId])
        );
        localStorage.setItem("config", normalEncryptData(res.data?.ProfilePic));
        navigate("/dashboard");
        toast.success("Login Successfully");
      } else {
        toast.error(res.message);
      }
    }
  };

  //*********Password toggle hide show******** */
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  // checks user is logged in or not
  // React.useEffect(() => {
  //   if (cookies) {
  //     navigate("/dashboard");
  //   }
  // }, [navigate, cookies]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Login</title>
      <div className="LoginArea login_section">
        <form method="POST">
          <div className="LoginBox">
            <figure>
              <img src={Logo} alt="logo" className=" my-2" />
            </figure>

            <h5 className="m-0">Sign in to Staff Panel</h5>

            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter Email Id"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-user"></i>
              </span>
              <span style={{ color: "red" }} className="error">
                {errors.email}
              </span>
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type={passwordType}
                placeholder="Enter Password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="passwordHideShow"
                onClick={(e) => {
                  togglePassword(e);
                }}
              >
                {passwordType === "password" ? (
                  <i className="fa fa-eye-slash"></i>
                ) : (
                  <i className="fa fa-eye"></i>
                )}
              </span>
              <span className="Icon">
                <i className="fa fa-unlock-alt"></i>
              </span>
              <span style={{ color: "red" }} className="error ">
                {errors.password}
              </span>
            </div>
            <button onClick={userLogin} ref={submitButtonElement}>
              Log In <i className="fa fa-sign-in"></i>
            </button>
            <br></br>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default Login;
