import axios from "axios";
import useCookie from "hooks/useCookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "utils";
import { decryptData } from "./config";
import { useStaffAuth } from "context/context";

// this code is created an instance with base url for staff
export const evaluatorInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/evaluator`,
});

export const moderatorInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/moderator`,
});

// this code is created an instance with base url for admin
export const adminInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
});

// this code is created an instance with base url for master
export const masterInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/master`,
});

// this function is used for set headers in api
export const setJwtToken = () => {
  return {
    headers: {
      Authorization: "Bearer " + isLoggedIn(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};

export const setMultiPartHeader = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
};

//this interceptors checks user's token is valid or not
export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const { roles } = useStaffAuth();
  const navigate = useNavigate();
  const { removeCookie } = useCookie();

  useEffect(() => {
    const resInterceptor = (response) => {
      if (decryptData(response).code === 401) {
        removeCookie();
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      return error;
    };
    let interceptor;
    if (roles.includes(5)) {
      interceptor = evaluatorInstance.interceptors.response.use(
        resInterceptor,
        errInterceptor
      );
    }
    if (roles.includes(6)) {
      interceptor = moderatorInstance.interceptors.response.use(
        resInterceptor,
        errInterceptor
      );
    }
    setIsSet(true);

    return () => {
      if (roles.includes(5)) {
        evaluatorInstance.interceptors.response.eject(interceptor);
      }
      if (roles.includes(6)) {
        moderatorInstance.interceptors.response.eject(interceptor);
      }
    };
    // eslint-disable-next-line
  }, [navigate]);

  return isSet && children;
};
