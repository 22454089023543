import React from "react";
import MuiButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export const Button = ({ children, to, loading, icon, endIcon, ...rest }) => {
  return (
    <>
      {/* If link */}
      {to ? (
        <Link to={to} {...rest}>
          <MuiButton
            // color="error"
            variant="outlined"
            {...rest}
          >
            {children}
          </MuiButton>
        </Link>
      ) : loading ? (
        <MuiButton
          // color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </MuiButton>
      ) : (
        <MuiButton
          // color="error"
          variant="outlined"
          {...rest}
          endIcon={icon && endIcon}
        >
          {children}
        </MuiButton>
      )}

      {/* if loading is true */}
      {/* {loading && (
        <MuiButton
          color="error"
          variant="outlined"
          startIcon={<CircularProgress size={20} color="inherit" />}
          {...rest}
        >
          {children}
        </MuiButton>
      )} */}
    </>
  );
};
