import { decryptData, encryptData } from "config/config";
import { adminInstance as axios } from "config/axiosInstance";

export const login = async (data) => {
  return decryptData(
    await axios.post(`/login`, { reqData: encryptData(data) })
  );
};

export const forgotPassword = async (data) => {
  return decryptData(
    await axios.post(`/forgot-password`, { reqData: encryptData(data) })
  );
};

export const changePassword = async (data) => {
  return decryptData(
    await axios.post(`/change-password`, { reqData: encryptData(data) })
  );
};

export const resetPassword = async (data) => {
  return decryptData(
    await axios.post(`/reset-password`, { reqData: encryptData(data) })
  );
};
