import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { adminInstance as axios } from "config/axiosInstance";

import ErrorImg from "assets/images/error.png";
import Approved from "assets/images/Approved.png";
import Logo from "assets/images/fullLogo.png";

const AccountVerify = () => {
  const { token } = useParams();
  const [message, setMessage] = useState({
    msg: "",
    status: "",
  });

  //*************Account verified*********************** */
  const verifyAccount = async () => {
    const { data } = await axios.get(`/auth/account_verify/${token}`);
    if (data.status === 200) {
      setMessage({
        ...message,
        msg: data.message,
        status: "Account Verified",
      });
    } else if (data.status === 422) {
      setMessage({ ...message, msg: data.message, status: "" });
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    verifyAccount();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <div className="LoginArea">
        <form method="POST">
          <div className="LoginBox flex_box _verifciation">
            <figure className="p-0">
              <img src={Logo} alt="Logo" className="my-2" />
            </figure>
            <div className="image_wrap">
              {message.status === "Account Verified" ? (
                <img src={Approved} alt="" className="approval_image mb-1" />
              ) : (
                <img src={ErrorImg} alt="" className="approval_image mb-1" />
              )}

              <h5>{message.status}</h5>
              {/* <h6>
              To keep connected wit us please login<br></br> wit your personal
              info.
            </h6> */}

              <div className="form-group">
                {message.status === "Account Verified" ? (
                  <p style={{ fontSize: "18px", color: "" }}>
                    <b>Thank You!</b>
                    {message.msg}
                  </p>
                ) : (
                  <p style={{ fontSize: "18px", color: "" }}>
                    <b>Error!</b>
                    {message.msg}
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default AccountVerify;
