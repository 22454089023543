import React, { createContext, useContext, useState } from "react";

const StaffContext = createContext();

export const StaffContextProvider = ({ children }) => {
  const [roles, setRoles] = useState("");
  return (
    <StaffContext.Provider value={{ roles, setRoles }}>
      {children}
    </StaffContext.Provider>
  );
};

export const useStaffAuth = () => {
  return useContext(StaffContext);
};
