import React, { lazy } from "react";

// MUI Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TaskIcon from "@mui/icons-material/Task";
import AssessmentIcon from "@mui/icons-material/Assessment";

// evaluators screens
const EvaluatorDashboard = lazy(() =>
  import("pages/Evaluator/Dashboard/Dashboard")
);
const OnlineOfflineEvaluation = lazy(() =>
  import("pages/Evaluator/OnlineOfflineEvaluation/OnlineOfflineEvaluation")
);
const Reevaluation = lazy(() =>
  import("pages/Evaluator/Reevaluation/Reevaluation")
);
const EvaluatorBilling = lazy(() =>
  import("pages/Evaluator/EvaluatorBilling/EvaluatorBilling")
);

// moderator screens
const ModeratorDashboard = lazy(() =>
  import("pages/Moderator/Dashboard/Dashboard")
);
const AssignedCopies = lazy(() =>
  import("pages/Moderator/AssignedCopies/AssignedCopies")
);
const ReassignedCopies = lazy(() =>
  import("pages/Moderator/ReassignedCopies/ReassignedCopies")
);

const ModeratedCopies = lazy(() =>
  import("pages/Moderator/ModeratedCopies/ModeratedCopies")
);

const BestCopies = lazy(() => import("pages/Moderator/BestCopies/BestCopies"));

const ChangePassword = lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);

export const sidebar = (roles = []) => {
  const sidebarData = [
    {
      id: 500000,
      enabled: roles.includes(5),
      name: "Dashboard",
      icon: <DashboardIcon fontSize="small" />,
      path: "/dashboard",
      isChild: null,
    },
    {
      id: 500001,
      enabled: roles.includes(5),
      name: "Evaluation Summary",
      icon: <CloudOffIcon fontSize="small" />,
      path: "/evaluation-summary",
      isChild: null,
    },
    {
      id: 500002,
      enabled: roles.includes(5),
      name: "Re Evaluations",
      icon: <AutorenewIcon fontSize="small" />,
      path: "/re-evaluations",
      isChild: null,
    },
    {
      id: 500003,
      enabled: roles.includes(5),
      name: "Evaluation Billing",
      icon: <PaymentIcon fontSize="small" />,
      path: "/evaluation-billing",
      isChild: null,
    },
    {
      id: 600000,
      enabled: roles.includes(6),
      name: "Dashboard",
      icon: <DashboardIcon fontSize="small" />,
      path: "/dashboard",
      isChild: null,
    },
    {
      id: 600001,
      enabled: roles.includes(6),
      name: "Assigned Copies",
      icon: <AssignmentIcon fontSize="small" />,
      path: "/assigned-copies",
      isChild: null,
    },
    {
      id: 600002,
      enabled: roles.includes(6),
      name: "Reassigned Copies",
      icon: <AssignmentReturnedIcon fontSize="small" />,
      path: "/reassigned-copies",
      isChild: null,
    },
    {
      id: 600003,
      enabled: roles.includes(6),
      name: "Moderated Copies",
      icon: <FileCopyIcon fontSize="small" />,
      path: "/moderated-copies",
      isChild: null,
    },
    {
      id: 600004,
      enabled: roles.includes(6),
      name: "Best Copies",
      icon: <TaskIcon fontSize="small" />,
      path: "/best-copies",
      isChild: null,
    },
    {
      id: 600005,
      enabled: false,
      name: "Reports",
      icon: <AssessmentIcon fontSize="small" />,
      path: "/reports",
      isChild: null,
    },
  ].filter((side) => side.enabled);

  return sidebarData;
};

export const routes = (roles = []) => {
  const routesData = [
    {
      id: 500000,
      enabled: roles.includes(5),
      element: <EvaluatorDashboard />,
      path: "/dashboard",
    },
    {
      id: 500001,
      enabled: roles.includes(5),
      element: <OnlineOfflineEvaluation />,
      path: "/evaluation-summary",
    },
    {
      id: 500002,
      enabled: roles.includes(5),
      element: <Reevaluation />,
      path: "/re-evaluations",
    },
    {
      id: 500003,
      enabled: roles.includes(5),
      element: <EvaluatorBilling />,
      path: "/evaluation-billing",
    },
    {
      id: 600000,
      enabled: roles.includes(6),
      element: <ModeratorDashboard />,
      path: "/dashboard",
    },
    {
      id: 600001,
      enabled: roles.includes(6),
      element: <AssignedCopies />,
      path: "/assigned-copies",
    },
    {
      id: 600002,
      enabled: roles.includes(6),
      element: <ReassignedCopies />,
      path: "/reassigned-copies",
    },
    {
      id: 600003,
      enabled: roles.includes(6),
      element: <ModeratedCopies />,
      path: "/moderated-copies",
    },
    {
      id: 600004,
      enabled: roles.includes(6),
      element: <BestCopies />,
      path: "/best-copies",
    },
    {
      enabled: true,
      element: <ChangePassword />,
      path: "/change-password",
    },
  ].filter((route) => route.enabled);
  return routesData;
};
