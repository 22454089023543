/** @format */

import React from "react";
import ErrorImg from "assets/images/404.png";
import { Link } from "react-router-dom";
import Logo from "assets/images/fullLogo.png";

const Error = () => {
  return (
    <>
      <div className="LoginArea">
        <div className="LoginBox">
          <figure>
            <img src={Logo} alt="logo" className="" />
          </figure>
          <div className="errorImg">
            <img src={ErrorImg} alt="" />
          </div>
          <h5>Page Not Found</h5>
          <Link to="/dashboard">Go to Home</Link>
        </div>
      </div>
    </>
  );
};

export default Error;
