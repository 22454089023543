import React, { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Logo from "assets/images/fullLogo.png";
import { forgotPassword } from "services/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setError] = useState({ email: "" });

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors["email"] = "Please enter registered Email Id";
    }
    setError(errors);
    return formIsValid;
  };
  //toast.configure();
  const handelForgotPassword = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const res = await forgotPassword({ email });
      if (res.code === 200) {
        setEmail("");
        toast.success(
          "Please check your email inbox. Password reset instructions sent to the associated email address."
        );
      } else {
        toast.error(res.message);
      }
    }
    //
  };
  return (
    <React.Fragment>
      <title>Shubra Viraj | Forgot Password</title>
      <div className="LoginArea">
        <form method="POST">
          <div className="LoginBox">
            <figure>
              <img src={Logo} alt="logo" className="my-2" />
            </figure>
            <h5>Forgot Password</h5>
            <h6>
              Please enter you registered email id.
              <br /> We will send you link to create new password.
            </h6>

            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter Email Id"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="Icon">
                <i className="fa fa-user"></i>
              </span>
              <span className="mt-2" style={{ color: "red" }}>
                {errors.email}
              </span>
            </div>

            <button onClick={handelForgotPassword}>
              Forgot Password <i className="fa fa-sign-in"></i>
            </button>
            <Link to="/" className="mt-3">
              Back
            </Link>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default ForgotPassword;
