import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { sidebar } from "routes/sidebar";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";
import { useStaffAuth } from "context/context";

const MobileMenu = ({ toggleDrawer, drawer, onClose, handelLogout }) => {
  const { roles } = useStaffAuth();
  return (
    <>
      {/* drawer */}
      <SwipeableDrawer
        anchor={"left"}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className="SidenavBar-mobile">
          <div className="" style={{ float: "right" }}>
            {/* <div className="" onClick={toggleDrawer(false)}>
              <i className="fa fa-times close"></i>
            </div> */}
          </div>
          <ul>
            {sidebar(roles).map(({ path, icon, name, isChild }) => {
              return isChild ? (
                <SubMenu
                  onClose={onClose}
                  isChild={isChild}
                  icon={icon}
                  name={name}
                  key={path}
                />
              ) : (
                <li className="" key={path}>
                  <NavLink to={path} onClick={onClose}>
                    <span>
                      {/* <i className={icon}></i> */}
                      {icon}
                    </span>
                    {name}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <NavLink to="/" onClick={handelLogout}>
                <span>
                  <i className="fa fa-sign-out"></i>
                </span>
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
